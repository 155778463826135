var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"sYmTtBVEskuFAKQgr0C-w"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/web/stg";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

import { isDevelopment } from './src/shared/util'
import { isE2E, isPrd } from './src/shared/util/env'

const dsn =
    process.env.SENTRY_DSN ||
    'https://8757ab63d5a842d69add259495c1327a@o177913.ingest.sentry.io/1385134'

Sentry.init({
    dsn,
    enabled: !isDevelopment() && !isE2E(),
    environment: process.env.SENTRY_ENVIRONMENT,
    tracesSampleRate: isPrd() ?  0.05 : undefined // sample 5% of transactions
})
